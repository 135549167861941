<template>
  <div class="mb-60 baojia-box liucheng-top rounded-lg">
    <v-card class="pa-4 d-flex">
      <v-row>
        <v-col xl="3" lg="3" md="12" sm="12">
          <v-card
            flat
            class="pa-2 d-flex align-center mb-4"
            color="#F7F7F7"
            width="250"
          >
            <div>
              <v-img
                width="52"
                class="mr-4"
                src="@/assets/images/person/wallet1.png"
              ></v-img>
            </div>
            <div class="text-left">
              <div class="mb-2 text-h5  font-weight-bold">¥ {{moneyinfo.ktx_money}}</div>
              <div>{{ $t("钱包余额") }}</div>
            </div>
          </v-card>
          <v-card
            flat
            class="pa-2 d-flex align-center mb-4"
            color="#F7F7F7"
            width="250"
          >
            <div>
              <v-img
                class="mr-4"
                width="52"
                src="@/assets/images/person/wallet2.png"
              ></v-img>
            </div>
            <div class="text-left">
              <div class="mb-2 text-h5  font-weight-bold">¥ {{moneyinfo.xd_money}}</div>
              <div>{{ $t("交易中订单返利") }}</div>
            </div>
          </v-card>
          <v-card
            flat
            class="pa-2 d-flex align-center"
            color="#F7F7F7"
            width="250"
          >
            <div>
              <v-img
                class="mr-4"
                width="52"
                src="@/assets/images/person/wallet3.png"
              ></v-img>
            </div>
            <div class="text-left">
              <div class="mb-2 text-h5  font-weight-bold">¥ {{moneyinfo.yg_money}}</div>
              <div>{{ $t("预估返利金额") }}</div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" xl="8" lg="8" md="12" sm="12">
          <v-card flat>
            <v-col cols="12" lg="6" sm="12" class="d-flex align-center">
              <v-card  rounded="pill" color="#F2F2F2" flat class="pl-2 pr-2 pt-1 pb-1 d-flex align-center">
                <v-menu
                  v-model="startshow"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="me-2">mdi-calendar-month</v-icon>
                    <span v-bind="attrs" v-on="on" v-if="searchObj.start">{{searchObj.start}}</span>
                    <span  v-bind="attrs" v-on="on"  v-else>{{$t('开始时间')}}</span>
                  </template>
                  <v-date-picker
                    v-model="searchObj.start"
                    @input="startshow = false"
                  />
                </v-menu>
                <v-icon>mdi-minus</v-icon>
                <v-menu
                  v-model="endshow"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" v-if="searchObj.end">{{searchObj.end}}</span>
                    <span  v-bind="attrs" v-on="on"  v-else>{{$t('结束时间')}}</span>
                  </template>
                  <v-date-picker
                    v-model="searchObj.end"
                    @input="endshow = false"
                  />
                </v-menu>
                <v-icon>mdi-menu-down</v-icon>
              </v-card>
            </v-col>
          </v-card>
          <div class="echartsc" id="main"></div>
          <!-- 折线图 -->
        </v-col>
      </v-row>
    </v-card>
    <!-- 订单 -->
    <v-card flat class="mt-6 pa-4">
      <!-- title -->
      <div class="d-flex flex-between mb-4">
        <div class="text-body-1 font-weight-bold">{{ $t("金额明细") }}</div>
        <div class="d-flex align-center text-right">
          <div style="width: 180px">
             <v-card flat >
              <v-select
                :items="typelist"
                dense
                flat
                solo
               background-color="#F2F2F2"
                v-model="patytype"
                :label="$t('交易状态')"
                item-value="id"
                item-text="name"
                @change="jiaoyiFn"
              ></v-select>

             </v-card>
          </div>
          <div class="d-flex align-center mr-4">
            <div style="width: 60px">{{ $t("时间：") }}</div>
            <v-card  max-width="400" color="#F2F2F2" flat class="pl-2 pr-2 pt-1 pb-1 d-flex align-center">
              <v-menu
                v-model="startshows"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="me-2">mdi-calendar-month</v-icon>
                    <span v-bind="attrs" v-on="on" v-if="searchorder.start">{{searchorder.start}}</span>
                    <span  v-bind="attrs" v-on="on"  v-else>{{$t('开始时间')}}</span>
                </template>
                <v-date-picker
                  v-model="searchorder.start"
                  @input="startshows = false"
                />
              </v-menu>
              <v-icon>mdi-minus</v-icon>
              <v-menu
                v-model="endshows"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" v-if="searchorder.end">{{searchorder.end}}</span>
                  <span  v-bind="attrs" v-on="on"  v-else>{{$t('结束时间')}}</span>
                </template>
                <v-date-picker v-model="searchorder.end" @input="endshows = false" />
              </v-menu>
              <v-icon>mdi-menu-down</v-icon>
            </v-card>
          </div>
          <div>
            <v-card
              flat
              class="pl-4 d-flex align-center flex-between"
              color="#F2F2F2"
            >
              <input
                :placeholder="$t('搜索关键词/订单编号')"
                class=""
                v-model="keyword"
                @keydown.enter="toserach"
              />
              <v-btn depressed class="" width="60" color="primary" @click="toserach">
                <v-icon color="#fff" size="28">mdi-magnify</v-icon>
              </v-btn>
            </v-card>
          </div>
        </div>
      </div>
      <!-- 订单信息 -->
      <div v-if="orderdata && orderdata.length>0">
        <div class="" v-for="(item, index) in orderdata" :key="index">
          <v-card
            flat
            color="#F2F2F2"
            class="pa-2 d-flex align-center flex-between"
          >
            <span class="d-flex">
              <div style="width:40px ">
                <v-img class="mr-4" src="@/assets/images/webtype/ali.png" v-if="item.from_web=='ali'"></v-img>
                <v-img class="mr-4" src="@/assets/images/webtype/pinduoduo.png" v-else-if="item.from_web=='pdd'"></v-img>
                
                <v-img class="mr-4"  src="@/assets/images/webtype/tao.png" v-else ></v-img>
              </div>
              <span class="mr-4">{{ $t("订单编号") }}: {{item.trade_parent_id}}</span>
              <span class="mr-4">{{$t('商品来源')}}: <span class="text-subtitleming-2 hidden-xs-only primary--text">{{item.from_web=='ali' ? '1688' : item.from_web=='pdd' ? $t('拼多多') : $t('淘宝')}}</span></span>
              <span class="mr-4">{{$t('平台结算状态')}}: <span class="text-subtitleming-2 hidden-xs-only primary--text">{{item.status=='2' ? $t('已结算') : $t('未结算')}}</span> </span>
              <span>{{$t('交易状态')}}: <span class="text-subtitleming-2 hidden-xs-only primary--text">{{item.tk_status=='1' ? $t('已签收') : $t('已下单')}}</span> </span>
            </span>
            <span class="grey--text">{{item.create_time | formFilter}}</span>
          </v-card>
          <v-row class="d-flex align-center mt-2 mb-2">
            <v-col cols="1">
              <div>
                <v-img
                 v-if="item.item_img"
                  width="70"
                  :src="item.item_img"
                ></v-img>
                <v-img  width="70"
                  v-else
                  src="@/assets/images/other/goods.png"></v-img>
              </div>
            </v-col>
            <v-col class="9">
              <div class="d-flex flex-between">
                <div class="">
                  <div>{{item.item_title}}</div>
                  <div class="grey--text mt-2 mb-2">{{$t('数量')}}</div>
                  <div>{{$t('返利金额')}}</div>
                </div>
                <div>
                  <div class="text-sharered font-weight-bold">¥{{item.item_price}}</div>
                  <div class="grey--text mt-2 mb-2">x{{item.item_num}}</div>
                  <div class="text-sharered">¥{{item.fl_money}}</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
      </div>
      <!-- 没有订单 -->
      <empty class="mt-8" :describe="$t('暂无订单~')" :imgSrc="emptysrc" v-else></empty>
      <div
        v-if="pagearr.pagetotal > 0"
        class="text-center d-flex mt-4"
        align="center"
      >
        <v-row align="center" class="justify-center">
          <span>共{{pagearr.pagetotal}}页</span>

          <v-pagination
            v-model="pagearr.page"
            :length="pagearr.pagetotal"
            total-visible="5"
            @input="getpagedata(1)"
          />
          <span class="mr-4 hidden-xs-only">{{ $t("条") }}</span>
          <span class="ml-6 hidden-xs-only">
            {{ $t("跳转至") }}
            <input
              v-model="pagearr.page"
              autocomplete="off"
              style="width: 80px"
              class="border in-border pl-2 pt-1 ml-2 mr-2 pb-1"
              type="number"
              @input="numpagedata"
              @blur="getpagedata(1)"
            />
            {{ $t("页") }}
          </span>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import empty from "@/components/base/empty";
import * as echarts from "echarts";
export default {
  components: { empty },
  data() {
    return {
      option: {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          align: "right",
          data: ["返利收入", "推广收入", "下单未到账"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          name: "日期",
          axisLabel: {
            rotate: 30,
            //X轴刻度配置
            interval: 0, //0：表示全部显示不间隔；auto:表示自动根据刻度个数和宽度自动设置间隔个数
          },
          type: "category",
          boundaryGap: false,

          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          name: "金额/¥",
          type: "value",
        },
        series: [
          {
            name: this.$t("返利收入"),
            type: "line",
            smooth: true,
            stack: "Total",
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: this.$t("推广收入"),
            type: "line",
            smooth: true,
            stack: "Total",
            data: [220, 182, 191, 234, 290, 330, 310],
          },
          {
            name: this.$t("下单未到账"),
            type: "line",
            smooth: true,
            stack: "Total",
            data: [150, 232, 201, 154, 190, 330, 410],
          },
        ],
      },
      typelist:[
        { name: this.$t("不限"), id: '' },
        { name: this.$t("已结算"), id: 2 },
        { name:  this.$t("未结算"), id: 1 },
      ],
      param: {},
      moneyinfo:{},
      orderdata:[],
      emptysrc:require('@/assets/images/other/zanwudingdan@2x.png'),
      startshow: false,
      endshow: false,
      searchObj: {
        end: "",
        start: "",
      },
      startshows: false,
      endshows: false,
      searchorder: {
        end: "",
        start: "",
      },
      keyword: "",
      patytype:"",
      pagearr: {
        pageList: [15, 30, 50, 100, 500, 1000, 2000],
        page: 1,
        pagenum: 15,
        pagetotal: 50,
        total: 0,
      },
    };
  },
  mounted() {
    this.param["page"] = this.pagearr.page;
    this.param["pagesize"] = this.pagearr.pagenum;
    
    
    this.initChart();
    this.getorder()
    this.getMoney()
  },
  methods: {
    getorder(){
      this.$api.order.fanliorder(this.param).then((res)=>{
        if(res.status=='success'){
          this.orderdata=res.data
          this.pagearr.pagetotal = Math.ceil(
            parseInt(res.total) / this.pagearr.pagenum
          );
        }else {
           this.orderdata=[]
           this.pagearr.pagetotal = 0;
        }
      })
    },
    getMoney(){
      this.$api.user.getMoney().then((res)=>{
        if(res.status=='success'){
          this.moneyinfo=res.data
        }else {
           this.moneyinfo={}
        }
      })
    },
    toserach() {
      this.param["start"] = this.searchObj.start;
      this.param["end"] = this.searchObj.end;
      this.param["sn"] = this.keyword;
      
      this.getorder()
    },
    // 点击第几页
    getpagedata(type) {
      if (type === 2) {
        this.pagearr.page = 1;
      }
      this.param["page"] = this.pagearr.page;
      this.param["pagesize"] = this.pagearr.pagenum;
      this.getorder();
    },
    numpagedata(){
      this.pagearr.page=Number( this.pagearr.page)
    },
    jiaoyiFn(){
       this.param["status"] = this.patytype;
      
      this.getorder()
    },
    initChart() {
      let that = this;
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      myChart.setOption(that.option);
    },
  },
};
</script>
<style scoped lang="scss">
.echartsc {
  max-width: 700px;
  height: 400px;
  @media (max-width: 500px) {
    width: 100%;
  }
}
input{
  outline: none;
}
.text-sharered {
  color: #f63605;
}
.flex-between {
  justify-content: space-between;
}
.in-border {
  border: 1px solid #cccccc;
}
</style>
<style scoped>
/deep/ .v-pagination  .v-pagination__item{
  box-shadow: none;
  background: #F2F2F2;
}
/deep/ .v-pagination__navigation{
  box-shadow: none;
  background: #F2F2F2 !important;
}
/deep/ .v-text-field.v-text-field--enclosed .v-text-field__details{
  display: none;
}
/deep/ .theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot{
  
}

</style>